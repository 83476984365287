import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../components/common';
import styled from 'styled-components';

const Styled404 = styled.div`
    .content-body {
        display: flex;
        justify-content: center;
    }

    .keyboardkitty {
        width: 50%;
        margin: 0 auto;
        padding-bottom: 10%;
        position: relative;
    }

    .giphy-embed {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .kittykitty {
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        opacity: 1;
    }
`;

const NotFoundPage = () => (
    <Styled404>
        <Layout>
            <div className="container">
                <article className="content" style={{ textAlign: `center` }}>
                    <h1 className="content-title">Whups! Error 404</h1>
                    <section className="content-body">
                        Page not found, and it&apos;s dangerous to go alone.
                        Take this <Link to="/">link home </Link> to start over
                        <div className="keyboardkitty">
                            <img
                                className="kittykitty"
                                src="https://media0.giphy.com/media/8l98VVUTKPhbG/giphy.gif"
                                alt="8 Bit Cat GIF by hoppip"
                            ></img>
                        </div>
                        <p>
                            <a href="https://giphy.com/gifs/hoppip-cat-hoppip-meme-8l98VVUTKPhbG">
                                via GIPHY
                            </a>
                        </p>
                    </section>
                </article>
            </div>
        </Layout>
    </Styled404>
);

export default NotFoundPage;
